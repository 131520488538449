import React from 'react';
import NavBar from './Navbar/NavBar';
import Portfolio from './Portfolio';
import Footer from './Footer';
import Intro from './Intro';


const  Intropage1= () => {
  return (
    <>
   <NavBar/>
        <br/>
        <br/>
            <Intro/>
            <Footer/>
        </>
    
  )
}

export default Intropage1;
